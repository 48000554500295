import React from "react";
import { css } from "@emotion/core";
import { bpMedium, bpLarge } from "../../stylingConstants";
import { HrefButton } from "../Atoms/Button";
import ShareLinks from "../Atoms/ShareLinks";

type Props = {
  link?: { url: string; text: string };
};

export default function RightColumnShare(props: Props) {
  return (
    <div
      css={css`
        margin-top: 50px;
        text-align: left;
        display: inline-block;
        @media ${bpMedium} {
          margin-top: 0;
        }
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column-reverse;
          @media ${bpMedium} {
            flex-direction: column;
          }
        `}
      >
        {props.link && (
          <div
            css={css`
              margin-top: 44px;
              @media ${bpMedium} {
                margin-top: 0;
                margin-bottom: 24px;
              }
              @media ${bpLarge} {
                margin-bottom: 44px;
              }
            `}
          >
            <HrefButton href={props.link.url}>{props.link.text}</HrefButton>
          </div>
        )}
        <div>
          <ShareLinks />
        </div>
      </div>
    </div>
  );
}
