import { css } from "@emotion/core";
import { graphql } from "gatsby";
import React from "react";
import { PersonTemplateQuery } from "../../graphql-schema-types";
import { Heading1, Intro } from "../components/Atoms/Text";
import ContentPageLayout from "../components/Organisms/ContentPageLayout";
import ContentPageRichText from "../components/Organisms/ContentPageRichText";
import { bpLarge, bpSmall } from "../stylingConstants";
import BackLink from "../components/Atoms/BackLink";
import GatsbyImage from "gatsby-image";
import { verticalMargin } from "../margins";
import RightColumnShare from "../components/Organisms/RightColumnShare";
import Seo from "../components/Seo";

export type Props = {
  data: PersonTemplateQuery;
  pageContext: {
    personYear: string;
    role: string;
  };
};

export default function Person({
  data: { contentfulPerson: person },
  pageContext: { personYear, role },
}: Props) {
  return (
    <ContentPageLayout
      leftColumn={<BackLink />}
      rightColumn={<RightColumnShare />}
    >
      <Seo
        title={person?.name}
        image={person?.image?.fluid?.src}
        description={
          person?.hostSpeakerPreamble?.hostSpeakerPreamble ??
          `${
            (person?.jobTitle ?? role) === "host"
              ? "Programvärd"
              : role === "speaker"
              ? "Gästtalare"
              : "Jurymedlem"
          } för Stora Journalistpriset ${personYear}`
        }
      />
      <Heading1>{person?.name}</Heading1>
      {person?.jobTitle && (
        <Intro
          css={css`
            margin-top: 12px;
            margin-bottom: 32px;
            @media ${bpLarge} {
              margin-top: 16px;
              margin-bottom: 44px;
            }
          `}
        >
          {person?.jobTitle}
        </Intro>
      )}
      {person?.image?.fluid && (
        <GatsbyImage
          css={css`
            max-width: 300px;
            ${verticalMargin(32)}
            @media ${bpLarge} {
              ${verticalMargin(44)}
            }
          `}
          fluid={person.image.fluid}
        />
      )}
      {person?.hostSpeakerPreamble?.hostSpeakerPreamble && (
        <Intro
          css={css`
            margin-top: 32px !important;
          `}
        >
          {person.hostSpeakerPreamble.hostSpeakerPreamble}
        </Intro>
      )}
      {person?.hostSpeakerInformation?.json && (
        <ContentPageRichText json={person.hostSpeakerInformation.json} />
      )}
      {person?.personalInformation?.json && (
        <ContentPageRichText json={person.personalInformation.json} />
      )}
    </ContentPageLayout>
  );
}

export const query = graphql`
  query PersonTemplate($id: String) {
    contentfulPerson(id: { eq: $id }) {
      name
      jobTitle
      personalInformation {
        json
      }
      hostSpeakerPreamble {
        hostSpeakerPreamble
      }
      hostSpeakerInformation {
        json
      }
      image {
        fluid(maxWidth: 300, quality: 95) {
          ...GatsbyContentfulFluid_noBase64
        }
      }
    }
  }
`;
