import React from "react";
import { Heading5 } from "./Text";
import { css } from "@emotion/core";
import { FacebookLogo, TwitterLogo } from "./Icons";
import { useLocation } from "@reach/router";

export default function ShareLinks() {
  const { pathname } = useLocation();
  const shareUrl = encodeURI(`${HOSTNAME}${pathname}`);
  return (
    <>
      <Heading5
        css={css`
          color: #828282;
          margin-bottom: 8px;
        `}
      >
        Dela
      </Heading5>
      <a
        href={`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`}
        target="_blank"
        style={{ marginRight: 12 }}
      >
        <FacebookLogo fill={"#828282"} />
      </a>
      <a
        href={`https://twitter.com/intent/tweet?url=${shareUrl}`}
        target="_blank"
      >
        <TwitterLogo fill={"#828282"} />
      </a>
    </>
  );
}
